/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, radius, colors, COLOR_CONSTANTS } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H3, Text } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import ImageText from 'routes/About/ImageText'
import Footer from 'components/Footer'
import avatar from 'static/svg/avatar.svg'
import bgImage from '../../static/images/experts/bg_image.jpg'
import monetize from '../../static/images/experts/expert_monetize.svg'
import discover from '../../static/images/experts/expert-discover.svg'
import message from '../../static/svg/message_red.svg'
import magazine from '../../static/svg/magazine_red.svg'
import social from '../../static/svg/social_red.svg'
import clock from '../../static/svg/clock_red.svg'
import list from '../../static/svg/list_red.svg'
import facebook from '../../static/svg/facebook_red.svg'
import lightning from '../../static/svg/lightning_red.svg'
import rocket from '../../static/svg/rocket_red.svg'
import instagram from '../../static/svg/instagram.svg'
import ExpertJoinCard from './ExpertJoinCard'

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const StyledBox = styled(Box)`
  padding: ${space.m} ${space.l} ${space.s} ${space.l};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 2px 7px rgba(39, 40, 49, 0.05);
`

const StyledFlex = styled(Flex)`
  box-shadow: 0px 4px 20px rgba(39, 40, 49, 0.1);
  border-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const LocalImageWrapper = styled(Flex)`
  min-width: 18px;
  min-height: 18px;
`

const Avatar = styled(LocalImage)`
  height: 75px;
  width: 75px;
  border-radius: ${radius.pill};
`

const IMAGE_TEXT_CONTENT = [
  {
    title: 'Discover New Opportunities To Advance Your Agency',
    body: `Agency Experts give digital professionals innovative insights and ideas into their organizations' needs to be successful. Gain access to 1-on-1 consulting and proven digital strategies to build awareness and credibility for your business.`,
    image: discover,
  },
  {
    title: 'Monetize Your Passion As An Agency Expert',
    body: `Instead of losing money on lead generation - as an Agency Expert you can <a href="mailto:yourfriends@agencyvista.com">get paid for it</a>! Agency Vista is a new platform for you to reach and engage with your target audience without competition or social media clutter. Showcase your content on your profile without having to continually monitor and manage it.`,
    image: monetize,
  },
]

const careerChangingList = [
  {
    description: 'Advice and support on how to grow your agency',
    image: message,
  },
  {
    description: 'Stay current with topics and trends in digital',
    image: magazine,
  },
  {
    description: 'Grow your network with Agency Experts communities ',
    image: social,
  },
  {
    description: `Time management and workload efficiency strategies`,
    image: clock,
  },
  {
    description: 'Custom management system that gives your company structure',
    image: list,
  },
  {
    description: `The ultimate study guide to help you prepare for becoming Facebook Blueprint Certified`,
    image: facebook,
  },
  {
    description: 'Learn how to attract your team with a magnet-like strength',
    image: lightning,
  },
  {
    description: 'Discover how to launch in a way that turns strangers into high-ticket clients',
    image: rocket,
  },
  {
    description: 'Instagram growth solutions to drive more qualified leads',
    image: instagram,
  },
]

const Experts = ({ pageContext: { experts } }) => {
  return (
    <App>
      <SEO
        title="Browse Agency Vista Verified Experts"
        description="Powered by data, we built the best platform to provide agencies with solutions to enable success for their
            business with valuable resources accessible at any time. Our Agency Experts are skilled marketers who are
            offering community, support, and educational resources around digital marketing."
        path="/experts/"
      />
      <Hero>
        <BgImg src={bgImage} alt="Browse Agency Vista Verified Experts" />
        <H1 color="white">Agency Expert’s Ready To Scale Your Growth</H1>
        <Box mt="m" maxWidth="900px">
          <StyledH3 color="white">
            Powered by data, we built the best platform to provide agencies with solutions to enable success for their
            business with valuable resources accessible at any time. Our Agency Experts are skilled marketers who are
            offering community, support, and educational resources around digital marketing.
          </StyledH3>
        </Box>
      </Hero>
      <Container maxWidth="1080px">
        <Box mt="xxl">
          {IMAGE_TEXT_CONTENT.map((content, index) => (
            <Box key={content.title} mb="xxl">
              <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 === 0} {...content} />
            </Box>
          ))}
        </Box>
        <Flex px="m" flexDirection="column" alignItems="center">
          <H1 mt="xl" textAlign="center">
            Career-Changing Value
          </H1>
          <Grid
            mt="m"
            py="m"
            px="s"
            width={['auto', 'auto', '900px']}
            gridTemplateColumns={{ mobile: '1fr', mobileLarge: '1fr 1fr', tablet: 'repeat(3, 1fr)' }}
            gridGap="m"
          >
            {careerChangingList.map(({ image, description }) => (
              <StyledFlex key={description} px="l" backgroundColor="#ffffff" pt="l" width="300px" height="120px">
                <LocalImageWrapper justifyContent="center" mt="4px">
                  <LocalImage src={image} width="18px" height="18px" />
                </LocalImageWrapper>
                <Text ml="m" as="span" fontSize="s">
                  {description}
                </Text>
              </StyledFlex>
            ))}
          </Grid>
        </Flex>
        <Flex mt="xxl" flexDirection="column" alignItems="center" textAlign="center">
          <H1>Browse Agency Vista Verified Experts</H1>
          <Text mt="m" color="secondaryText" opacity={0.8} fontSize="l">
            Search and connect with thousands of verified social media experts.
          </Text>
          <Grid
            mt="m"
            py="m"
            px="s"
            width={['auto', 'auto', '900px']}
            gridTemplateColumns={{
              mobileLarge: '1fr',
              tablet: 'repeat(2, 1fr)',
              desktop: 'repeat(3, 1fr)',
            }}
            gridGap="m"
          >
            {experts.map(
              ({
                city,
                country: { name: countryName },
                link_code,
                services,
                user: { id, first_name, last_name, picture },
              }) => {
                const location = []
                if (city) {
                  location.push(city)
                }
                if (countryName) {
                  location.push(countryName)
                }
                return (
                  <StyledBox key={id} minWidth="300px" backgroundColor={colors.white}>
                    <Flex flexDirection="column" py="l">
                      <Flex justifyContent="center" width="100%">
                        <Avatar source={picture || avatar} />
                      </Flex>
                      <Text
                        mt="m"
                        as="a"
                        href={`/expert/${link_code}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        color="primary"
                        fontSize="l"
                        fontWeight="bold"
                      >
                        {first_name} {last_name}
                      </Text>
                      <Text fontSize="xs" my="xs">
                        {location.join(', ')}
                      </Text>
                      <Box mt="m">
                        <Text fontSize="xs">{services.map(({ name }) => name).join(', ')}</Text>
                      </Box>
                    </Flex>
                  </StyledBox>
                )
              }
            )}
            <StyledBox backgroundColor="#F8F9FA">
              <ExpertJoinCard />
            </StyledBox>
          </Grid>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Experts.propTypes = {
  pageContext: PropTypes.shape({
    experts: PropTypes.array.isRequired,
  }).isRequired,
}

export default Experts
